import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { MIN_STRING_DISTANCE } from "@/app/constants.mjs";
import { ROLE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import {
  dataDuosList,
  dataSinglesList,
  getKeyForArenaChampionsData,
  getPatchForArenaData,
} from "@/game-lol/utils/arena-queue-utils.mjs";
import { commonChampionsStats } from "@/game-lol/utils/champions-stats-utils.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  getCurrentPatchForStaticData,
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
  isARAMQueue,
  isArenaQueue,
} from "@/game-lol/utils/util.mjs";
import stringCompare from "@/util/string-compare.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const useChampionsTierlist = ({
  tab,
  filterParams,
  searchParams,
  isArenaSingles,
  isArenaDuos,
}) => {
  const { searchText, role, queue } = filterParams;
  const patch = getCurrentPatchForStaticData();
  const champions = getStaticData("champions", patch);
  const isArena = isArenaSingles || isArenaDuos;

  const {
    volatile,
    lol: { championsStats },
  } = useSnapshot(readState);
  void volatile.lolStaticDataLoaded;

  const urlParams = getSearchParamsForChampions(
    false,
    getDefaultedFiltersForChampions(searchParams, tab, null),
  );

  const stats = isArena
    ? championsStats?.[
        getKeyForArenaChampionsData(getPatchForArenaData(), isArenaDuos)
      ]
    : championsStats?.[urlParams.toString()];

  const tableData = useMemo(() => {
    if (isArenaSingles) {
      return dataSinglesList(stats, champions);
    } else if (isArenaDuos) {
      return dataDuosList(stats, champions);
    }

    return commonChampionsStats(stats)
      .map((c) => {
        const {
          champion_id: championId,
          individual_position: role,
          champion_role_tier: tier,
          win_rate: winRate,
        } = c;
        const championKey = champions?.keys?.[championId];
        const champion = champions[championKey];
        const championName = champion?.name || "";
        return {
          id: championId,
          role,
          championKey,
          championName,
          tier,
          winRate,
        };
      })
      .filter(Boolean);
  }, [champions, stats, isArenaSingles]);

  const filteredData = useMemo(() => {
    if (!tableData) return null;

    return tableData
      ?.filter((c) => {
        const name = !isArenaDuos
          ? c.championName
          : `${c.champion1Name}${c.champion2Name}`;

        const matchesSearch =
          !searchText || stringCompare(searchText, name) > MIN_STRING_DISTANCE;

        const matchesRole =
          !role ||
          isARAMQueue(queue) ||
          isArenaQueue(queue) ||
          RoleSymbol(role) === ROLE_SYMBOLS.all
            ? true
            : RoleSymbol(c.role) === RoleSymbol(role);

        return matchesSearch && matchesRole;
      })
      ?.sort((a, b) => {
        return (
          a.tier - b.tier ||
          b.top4Rate - a.top4Rate ||
          b.winRate - a.winRate ||
          b.games - a.games
        );
      })
      ?.reduce((acc, curr) => {
        const grp = curr.tier - 1;

        if (!acc[grp]) acc[grp] = [];
        acc[grp] = [...acc[grp], curr];

        return acc;
      }, []);
  }, [tableData, searchText, role, queue]);

  return {
    data: filteredData,
    loading: !stats,
  };
};

export default useChampionsTierlist;
