import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { winRatecolorRange } from "@/app/util.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  ROLE_SYMBOL_TO_STR,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import {
  ChampionBlock,
  GridContainer,
  Row,
  RowContainer,
  Tier,
  TierGroup,
} from "@/game-lol/TierList.style.jsx";
import {
  getPatchForArenaData,
  top4Color,
  usePatchForArenaData,
} from "@/game-lol/utils/arena-queue-utils.mjs";
import { patchDisplay } from "@/game-lol/utils/champions-stats-utils.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon-path.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import useChampionsTierList from "@/game-lol/utils/use-champions-tier-list.mjs";
import useChampionsFilter from "@/game-lol/utils/useChampionsFilter.jsx";
import {
  getCurrentPatchForStaticData,
  prettyPatch,
  useChampionsStatsPatch,
} from "@/game-lol/utils/util.mjs";
import { DataTableNoResults } from "@/shared/DataTable.jsx";
import getTierColor from "@/shared/get-tier-color.mjs";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import SuppressHydrationWarnings from "@/util/SuppressHydrationWarnings.jsx";
import SymbolMap from "@/util/symbol-map.mjs";

const TABS = {
  [undefined]: {
    url: () => `/${lolRefs.lolTierlistPrefix}`,
    title: ["lol:queueTypes.ranked_solo", "Ranked Solo"],
    titleMetaInfo: (patchNumber, rank) => {
      return [
        "lol:meta.tierlist.titleTierPatchRanked",
        "League of Legends Ranked Tier List, {{tier}} Patch {{patchNumber}}",
        { tier: rank, patchNumber: patchDisplay(patchNumber, true) },
      ];
    },
  },
  aram: {
    url: () => `/${lolRefs.lolTierlistPrefix}/aram`,
    title: ["lol:queueTypes.aram", "ARAM"],
    titleMetaInfo: (patchNumber) => {
      return [
        "lol:meta.tierlist.titlePatchAram",
        "League of Legends ARAM Tier List, Patch {{patchNumber}}",
        { patchNumber: patchDisplay(patchNumber, true) },
      ];
    },
  },
  // "one-for-all": {
  //   url: () => `/${lolRefs.lolTierlistPrefix}/one-for-all`,
  //   title: ["lol:queueTypes.oneForAll", "One For All"],
  //   titleMetaInfo: (patchNumber) => {
  //     return [
  //       "lol:meta.tierlist.titlePatchOneForAll",
  //       "One For All Tier List, Patch {{patchNumber}}",
  //       { patchNumber },
  //     ];
  //   },
  // },
  arena: {
    url: () => `/${lolRefs.lolTierlistPrefix}/arena`,
    title: ["lol:arena", "Arena"],
    titleMetaInfo: (patchNumber) => {
      return [
        "lol:meta.tierlist.titlePatchArena",
        "Arena Tier List, Patch {{patchNumber}}",
        { patchNumber: patchDisplay(patchNumber, true) },
      ];
    },
  },
  "arena-duos": {
    url: () => `/${lolRefs.lolTierlistPrefix}/arena-duos`,
    title: ["lol:arenaDuos", "Arena Duos"],
    titleMetaInfo: (patchNumber) => {
      return [
        "lol:meta.tierlist.titlePatchArenaDuos",
        "Arena Duos Tier List, Patch {{patchNumber}}",
        { patchNumber: patchDisplay(patchNumber, true) },
      ];
    },
  },
  // "nexus-blitz": {
  //   url: () => `/${lolRefs.lolTierlistPrefix}/nexus-blitz`,
  //   title: ["lol:queueTypes.nexus_blitz", "Nexus Blitz"],
  //   titleMetaInfo: (patchNumber) => {
  //     return [
  //       "lol:meta.tierlist.titlePatchNexusBlitz",
  //       "Nexus Blitz Tier List, Patch {{patchNumber}}",
  //       { patchNumber },
  //     ];
  //   },
  // },
  // urf: {
  //   url: () => `/${lolRefs.lolTierlistPrefix}/urf`,
  //   title: ["lol:queueTypes.urf", "URF"],
  //   titleMetaInfo: (patchNumber) => {
  //     return [
  //       "lol:meta.tierlist.titlePatchUrf",
  //       "URF Tier List, Patch {{patchNumber}}",
  //       { patchNumber },
  //     ];
  //   },
  // },
};

const TierList = () => {
  const { t } = useTranslation();
  const {
    parameters: [tab],
  } = useRoute();

  const champStatsPatch = useChampionsStatsPatch();
  const arenaStatsPatch = usePatchForArenaData();

  const isArena = tab === "arena" || tab === "arena-duos";
  const filters = useChampionsFilter({ tab });

  const rank = SymbolMap.entries(RANK_SYMBOL_TO_STR).find(([_, { gql }]) =>
    filters.tier ? filters.tier === gql : true,
  )?.[1]?.t;
  const patch = isArena ? arenaStatsPatch : champStatsPatch;
  const title =
    patch && TABS[tab]?.titleMetaInfo
      ? TABS[tab].titleMetaInfo(patch, rank && t(rank.name, rank.fallback))
      : ["lol:meta.tierlist.title", "LoL Tier List"];

  if (!TABS[tab]) return <h1>{tab}</h1>;

  return (
    <PageContainer>
      <PageHeader
        title={t(...title)}
        links={Object.values(TABS).map(({ url, title }) => ({
          url: typeof url === "function" ? url() : url,
          text: title,
        }))}
        navLabel="Tier lists"
      />
      <ChampionsList filters={filters} />
    </PageContainer>
  );
};

export default TierList;

function ChampionsList({ filters }) {
  const {
    searchParams,
    parameters: [tab],
  } = useRoute();
  const isArenaSingles = tab === "arena";
  const isArenaDuos = tab === "arena-duos";

  const tierNames = ["S", "A", "B", "C", "D"];

  const { FilterBar, ...filterParams } = filters;
  const { data, loading } = useChampionsTierList({
    tab,
    filterParams,
    searchParams,
    isArenaSingles,
    isArenaDuos,
  });

  if (loading) {
    return (
      <>
        {FilterBar}
        <Card loading style={{ height: 800 }} />
      </>
    );
  } else if (!data.length) {
    return (
      <>
        {FilterBar}
        <DataTableNoResults />
      </>
    );
  }

  return (
    <>
      {FilterBar}
      <Card padding={0}>
        <SuppressHydrationWarnings>
          {data.map((group, index) => {
            const TierIcon = getTierIcon(index + 1);

            return (
              <RowContainer key={index}>
                <Row style={{ "--tier-color": getTierColor(index + 1) }}>
                  <Tier>
                    {TierIcon && <img src={TierIcon} width="40" height="40" />}
                    <span className="visually-hidden">{tierNames[index]}</span>
                  </Tier>
                  <GridContainer>
                    <TierGroup>
                      {group
                        .sort((a, b) => b.top_4_percent - a.top_4_percent)
                        .map((c, i) => {
                          if (isArenaDuos) {
                            return (
                              <Duo
                                key={`${c.champion1Key}_${c.champion2Key}`}
                                champ1Id={c.id1}
                                champ2Id={c.id2}
                                champ1Key={c.champion1Key}
                                champ2Key={c.champion2Key}
                                champ1Name={c.champion1Name}
                                champ2Name={c.champion2Name}
                                winRate={c.winRate}
                                top4={c.top4Rate}
                                isArena={true}
                                params={filterParams}
                              />
                            );
                          }

                          return (
                            <Champion
                              key={`${c.championKey}_${i}`}
                              champId={c.id}
                              champKey={c.championKey}
                              champName={c.championName}
                              winRate={c.winRate}
                              top4={c.top4Rate}
                              role={
                                c.role ? ROLE_SYMBOL_TO_STR[c.role]?.gql : null
                              }
                              isArena={c.isArena}
                              params={filterParams}
                            />
                          );
                        })}
                    </TierGroup>
                  </GridContainer>
                </Row>
              </RowContainer>
            );
          })}
        </SuppressHydrationWarnings>
      </Card>
    </>
  );
}

function champLink(key, queue, params) {
  delete params.searchText;

  if (queue === getQueueDetails(QUEUE_SYMBOLS.aram).gql) {
    const linkParams = new URLSearchParams({ region: params.region });
    return `/${lolRefs.lolChampionPrefix}/${key}/aram?${linkParams.toString()}`;
  } else if (queue === getQueueDetails(QUEUE_SYMBOLS.nexusBlitz).gql) {
    const linkParams = new URLSearchParams({ region: params.region });
    return `/${
      lolRefs.lolChampionPrefix
    }/${key}/nexus-blitz?${linkParams.toString()}`;
  } else if (queue === getQueueDetails(QUEUE_SYMBOLS.arena).gql) {
    return `/${lolRefs.lolChampionPrefix}/${key}/arena`;
  } else if (queue === getQueueDetails(QUEUE_SYMBOLS.urf).gql) {
    return `/${lolRefs.lolChampionPrefix}/${key}/urf`;
  } else if (queue === getQueueDetails(QUEUE_SYMBOLS.oneForAll).gql) {
    return `/${lolRefs.lolChampionPrefix}/${key}/one-for-all`;
  }

  const linkParams = new URLSearchParams({ ...params });
  return `/${lolRefs.lolChampionPrefix}/${key}/build?${linkParams.toString()}`;
}

const Champ = ({
  champId,
  champKey,
  champName,
  winRate,
  top4,
  role,
  isArena,
  params,
}) => {
  const { t } = useTranslation();
  const link = champLink(champKey, params.queue, { ...params, role });
  const RoleIcon = role ? getRoleIcon(role) : null;

  if (!champName) return null;

  return (
    <SuppressHydrationWarnings>
      <ChampionBlock className={!role && "no-role"}>
        <a href={link}>
          <ChampionImg
            size={36}
            championId={champId}
            className="champion-image"
          />
          {RoleIcon && (
            <div className="champion-role">
              <img src={RoleIcon} width="24" height="24" />
              <span className="visually-hidden">{role}</span>
            </div>
          )}
          <div className="meta">
            <span className="name type-subtitle--bold">{champName}</span>
            <div>
              {!isArena ? (
                <span
                  className="type-caption--semi"
                  style={{
                    display: "block",
                    color: winRatecolorRange(winRate * 100),
                  }}
                >
                  {winRate.toLocaleString(getLocale(), {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                    style: "percent",
                  })}
                </span>
              ) : (
                <>
                  <span
                    className="type-caption"
                    style={{ color: "var(--shade1)" }}
                  >
                    {t("tft:topFourPercentage", "Top 4%")} -{" "}
                  </span>
                  <span
                    className="type-caption--semi"
                    style={{
                      color: top4 && top4Color(top4 * 100),
                    }}
                  >
                    {top4.toLocaleString(getLocale(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                      style: "percent",
                    })}
                  </span>
                </>
              )}
            </div>
          </div>
        </a>
      </ChampionBlock>
    </SuppressHydrationWarnings>
  );
};
const Champion = React.memo(Champ);

const DuoGrp = ({
  champ1Id,
  champ2Id,
  champ1Name,
  champ2Name,
  top4,
  isArena,
}) => {
  const { t } = useTranslation();

  if (!champ1Name || !champ2Name) return null;

  return (
    <SuppressHydrationWarnings>
      <ChampionBlock>
        <div className="arena-duos">
          <ChampionImg
            size={28}
            championId={champ2Id}
            className="champion-image champion2-image"
            round={false}
          />
          <ChampionImg
            size={28}
            championId={champ1Id}
            className="champion-image champion1-image"
            round={false}
          />
          <div className="meta">
            <p className="type-body2 name">{champ1Name}</p>
            <p className="type-body2 name">{champ2Name}</p>
            <div>
              {isArena && (
                <span
                  className="type-caption"
                  style={{ color: "var(--shade1)" }}
                >
                  {t("tft:topFourPercentage", "Top 4%")} -{" "}
                </span>
              )}
              <span
                className="type-caption--bold"
                style={{
                  color: top4Color(top4 * 100),
                }}
              >
                {top4.toLocaleString(getLocale(), {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                  style: "percent",
                })}
              </span>
            </div>
          </div>
        </div>
      </ChampionBlock>
    </SuppressHydrationWarnings>
  );
};
const Duo = React.memo(DuoGrp);

export function meta(params) {
  const patch = getCurrentPatchForStaticData();
  const tabId = params[0];

  const patchDisplay = patch && prettyPatch(patch);
  const arenaPatch = getPatchForArenaData();

  let title;
  let description;

  switch (tabId) {
    case "one-for-all":
      title = ["lol:meta.tierlist.titleOneForAll", "One For All Tier List"];
      description = [
        "lol:meta.tierlist.descriptionOneForAll",
        "Get ahead of the competition in the League of Legends One For All mode with our LoL Ranked tier list. View the League One For All tierlist available for Patch {{patch}}.",
        { patch: patchDisplay },
      ];
      break;
    case "nexus-blitz":
      title = ["lol:meta.tierlist.titleNexusBlitz", "Nexus Blitz Tier List"];
      description = [
        "lol:meta.tierlist.descriptionNexusBlitz",
        "Get ahead of the competition in the League of Legends Nexus Blitz mode with our LoL Ranked tier list. View the League Nexus Blitz tierlist available for Patch {{patch}}.",
        { patch: patchDisplay },
      ];
      break;
    case "aram":
      title = ["lol:meta.tierlist.titleAram", "ARAM Tier List"];
      description = [
        "lol:meta.tierlist.descriptionAram",
        "Get ahead of the competition in the League of Legends ARAM mode with our LoL ARAM tier list. View the League ARAM tierlist available for Patch {{patch}}.",
        { patch: patchDisplay },
      ];
      break;
    case "arena":
      title = ["lol:meta.tierlist.titleArena", "Arena Tier List"];
      description = [
        "lol:meta.tierlist.descriptionArena",
        "Get ahead of the competition in the League of Legends Arena 2v2v2v2 mode with our LoL Arena tier list. View the LoL Arena tierlist available for Patch {{patch}}.",
        { patch: arenaPatch },
      ];
      break;
    case "arena-duos":
      title = ["lol:meta.tierlist.titleArenaDuos", "Arena Duos Tier List"];
      description = [
        "lol:meta.tierlist.descriptionArenaDuos",
        "Get ahead of the competition in the League of Legends Arena 2v2v2v2 mode with our LoL Arena duos tier list. View the LoL Arena duos tierlist available for Patch {{patch}}.",
        { patch: arenaPatch },
      ];
      break;
    default:
      title = ["lol:meta.tierlist.title", "LoL Tier List"];
      description = [
        "lol:meta.tierlist.description",
        "Get ahead of the competition in the League of Legends Ranked Solo mode with our LoL Ranked tier list. View the League Ranked Solo tierlist available for Patch {{patch}}.",
        { patch: patchDisplay },
      ];
  }

  return {
    title,
    description,
    subtitle: true,
  };
}
